.buttons-wrapper {
    margin-top: 25px;

    img {
        max-height: 44px;
    }
}

.copy-link {
    font-size: 14px;
    color: #152F52;
    cursor: pointer;
    background: none;
    border: none;
    margin: 0;
    padding: 0;

    &:hover {
        color: #000;
    }

    svg {
        width: 20px;
        height: 20px;
        margin-right: 7px;
    }
}

.mobile-button {
    background-color: #000;
    padding: 0 !important;
    border: none;

    img {
        max-height: 44px;
    }
}